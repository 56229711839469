<template>
  <div>
    <div class="flix-container-fluid flix-html-html" style="padding: 50px 0; background-color:#717C80; color: #fff;">
      <h2 class="flix-text-center flix-html-h1 flix-text-light"><flixIcon id="calendar" /></h2>
      <h2 class="flix-text-center flix-html-h1 flix-text-light"><bookingflix-calendar-items style="display: block; margin-top: 10px;" :settings='settings'></bookingflix-calendar-items></h2>
      <div class="flix-text-center pb_txt">- <bookingflix-calendar-items style="display: inline-block; margin: 10px 0 20px 0;" :settings='settings2'></bookingflix-calendar-items> -</div>
      <b class="flix-text-center pb_txt" v-if="title" v-html="title" style="display: block;"></b>
    </div>
    <div class="flix-container-fluid flix-html-html" style="padding: 0;">
      <div :class="'flix-bg-'+$getStatusClass(data.status)" style="width: 100%; height: 10px" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      title: JSON.parse(this.data.atitle).title,
      settings: JSON.stringify({ lang: this.$i18n.locale, view: 'date-string', date: [this.data.begin, this.data.begin] }),
      settings2: JSON.stringify({ lang: this.$i18n.locale, view: 'timer', date: [this.data.begin, this.data.begin] })
    }
  },
  methods: {
    getAssistentTitle () {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.$route.params.user,
          ID: this.data.form
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.title = ret.data[1].title
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getAssistentTitle()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
